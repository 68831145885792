<template>
  <div class="">
      <div class="flex items-center space-x-3" v-for="person in presentPersons" :key="person.name">
        <img class="w-80px h-80px my-2" v-if="person.photo" :src=person.photo>
        <img class="w-80px h-80px my-2" v-else src="../assets/profile.png">
        <p>{{person.name}}</p>
      </div>
  </div>
</template>

<script>
import graph from '../helpers/graph'

export default {
  name: 'PresenceList',
  props: {
    msg: String
  },
  data() {
    return {
      presentPersons: [],
      presenceTimer: null,
      startdate: null,
      enddate: null,
      error: ''
    }
  },
  created() {
    let date = new Date();
    date.setHours(6,0,0,0); // hacky cuz we don't fuck around with time zones
    this.startdate = date.toISOString()
    date.setHours(20,59)
    this.enddate = date.toISOString()
  },
  mounted() {
    this.fetchPresentPersons()

    this.presenceTimer = setInterval(() => {
      this.fetchPresentPersons()
    }, 240000) // every 240 seconds (4 minutes)

  },
  methods: {
    async fetchPresentPersons() {
      try {
        let persons = []

        // fetch json data from graph API
        let response = await graph.callGraph('/me/calendars/' + process.env.VUE_APP_PRESENCE_CALENDAR_ID + '/calendarView?startDateTime='+this.startdate +'&endDateTime='+ this.enddate)

        // parse json to get all names
        // this cant be forEach() because it does not support async await
        for (let i = 0; i < response.value.length; i++) {
          for (let j = 0; j < response.value[i].attendees.length; j++) {

            let address = response.value[i].attendees[j].emailAddress.address
            let name = response.value[i].attendees[j].emailAddress.name

            // filtering out the room itself
            if (process.env.VUE_APP_PRESENCE_CALENDAR_ADDRESS !== address) {

              //get the profile photo
              // let photo = await graph.getPhoto('/users/' + address +'/photos/240x240/$value', true)

              persons.push({
                    "name" : name,
                    "address" : address,
                    "photo": null
                  })
            }
          } //inner for-loop
        } // outer for-loop

        // which persons are only in the "old" array and not in the new one?
        // -> remove them
        //let difference = this.presentPersons.filter(x => !persons.includes(x));
        let personsToPop = this.presentPersons.filter(this.comparer(persons))
        // pop them
        personsToPop.forEach((personToPop) => {
          this.presentPersons = this.presentPersons.filter(function(e) { return e.name !== personToPop.name; });
        })

        // persons who are only in the "new" array
        let personsToPush = persons.filter(this.comparer(this.presentPersons))
        // get the photos for the new people
        for (let i = 0; i < personsToPush.length; i++) {
          personsToPush[i].photo = await graph.getPhoto('/users/' + personsToPush[i].address +'/photos/240x240/$value', true)
        }
        // push them to array
        this.presentPersons = this.presentPersons.concat(personsToPush)

      } catch (err) {
        this.error = err
        console.log(err)
      }
    },
    // https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript
    comparer(otherArray) {
      return function(current){
        return otherArray.filter(function(other){
          return other.value === current.value && other.display === current.display
        }).length === 0;
      }
    }
  }
}
</script>


