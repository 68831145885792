<template>
  <div class="centered">
    <h1 class="title is-5">
      Please login
    </h1>
    <button class="button is-dark is-large" @click="doLogin">
      Sign in with Microsoft <!-- <img class="ml-4" src="../assets/mssymbol.svg" alt="MS logo">-->
    </button>
    <div v-if="error" class="notification is-warning mt-4">
      {{ error }}
    </div>
  </div>
</template>

<script>
// heavily influenced from https://github.com/benc-uk/msal-graph-vue/blob/7c279bfc2f3932f87147c45fbe81e3f25b3bbf3b/src/components/Login.vue
import auth from '../helpers/auth'
export default {
  data: function() {
    return {
      error: ''
    }
  },
  methods: {
    async doLogin() {
      try {
        await auth.login()
        this.$emit('loginComplete')
      } catch (err) {
        this.error = err.toString()
      }
    }
  }
}
</script>

<style scoped>
.centered {
  text-align: center;
}
</style>