<template>
  <div id="app" class="flex justify-around w-full">
    <!-- Account Details -->
    <button @click="showUserDetails = !showUserDetails" class="fixed bottom-0 right-0 text-xs border border-4 rounded p-1 m-1">Account Details</button>
    <div v-if="showUserDetails">
      <h1 class="title">Account</h1>
      <div v-if="graphPhoto" class="column">
        <p><img :src="graphPhoto" alt="profile picture" /></p>
      </div>
      <div v-if="user && !error" class="columns is-multiline">
        <p><b>Name:</b> {{ user.name }}</p>
        <p><b>Username:</b> {{ user.username }}</p>
        <button @click="shallowLogout">Logout (Local)</button>
        <button @click="fullLogout">Logout (Full)</button>
      </div>
    </div>

    <!-- left -->
      <div class="flex-1 w-half flex justify-around">
        <div class="flex-col">
        <h1 class="text-3xl font-bold mt-8">Heute im Seki anwesend:</h1>
        <PresenceList class="text-xl"></PresenceList>
        </div>
      </div>

    <!-- right -->
    <div class="flex-1 h-screen">
      <div class="flex-col">
        <h1 class="text-3xl font-bold mt-8">Sitzungszimmer:</h1>
        <ConferenceRoomCalendar class="text-xl"></ConferenceRoomCalendar>
      </div>
    </div>

    <Login v-if="!user && !error" @loginComplete="updateUser" />

      <!-- uncomment for debug
      User Info:
      <pre>
        {{JSON.stringify(user, null, 2)}}
      </pre>
      Graph Details:
      <pre>
        {{JSON.stringify(graphDetails, null, 2)}}
      </pre>
      -->

  </div>
</template>

<script>
import PresenceList from "./components/PresenceList";
import ConferenceRoomCalendar from "./components/ConferenceRoomCalendar"
import Login from "./components/Login";
import auth from './helpers/auth'
import graph from './helpers/graph'

export default {
  name: 'App',
  components: {
    PresenceList,
    ConferenceRoomCalendar,
    Login
  },
  data: function() {
    return {
      // User account object synced with MSAL getAccount()
      user: {},
      // Access token fetched via MSAL for calling Graph API
      accessToken: '',
      // Details fetched from Graph API, user object and photo
      graphDetails: null,
      graphPhoto: null,
      // Visibility toggle
      showUserDetails: false,
      reloadTimer: null,
      // Any errors
      error: ''
    }
  },
  watch: {
    // If user changes, make calls to Graph API
    user: async function() {
      await this.fetchGraphDetails()
    }
  },
  async created() {
    // Basic setup of MSAL helper with client id, or give up
    if (process.env.VUE_APP_GRAPH_API_CLIENTID) {
      auth.configure(process.env.VUE_APP_GRAPH_API_CLIENTID, process.env.VUE_APP_GRAPH_API_TENANT)
      // Restore any cached or saved local user
      this.user = auth.user()
      console.log(`confgied ${auth.isConfigured()}`)
    } else {
      this.error = 'VUE_APP_CLIENT_ID is not set, the app will not function! 😥'
    }
  },
  mounted () {
    this.reloadTimer = setInterval(() => {
      location.reload();
    }, 3600000) // every 3600 seconds (60 minutes)
  },
  methods: {
    // Update user from MSAL
    updateUser() {
      this.user = auth.user()
    },
    // Remove locally held user details, is same as logout
    shallowLogout() {
      this.user = null
      this.graphDetails = null
      this.userDetails = null
      this.graphPhoto = null
      auth.clearLocal()
    },
    // Full logout local & server side
    fullLogout() {
      auth.logout()
    },
    // Get an access token and call graphGetSelf & graphGetPhoto
    async fetchGraphDetails() {
      if (!this.user) {
        return
      }
      try {
        this.graphDetails = await graph.getSelf()
        this.graphPhoto = await graph.getPhoto()
        this.accessToken = graph.getAccessToken()
      } catch (err) {
        this.error = err
      }
    }
  }
}
</script>


