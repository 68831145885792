<template>
  <div class="">
    <div class="flex items-center space-x-3" v-for="event in events" :key="event.name">
      <img class="w-80px h-80px my-2" v-if="event.photo" :src=event.photo>
      <img class="w-80px h-80px my-2" v-else src="../assets/profile.png">
      <div>
        <p>{{ event.organizer }}</p>
        <p>{{ event.startdate | formatTime }} bis {{ event.enddate | formatTime }} </p>
      </div>

    </div>
  </div>
</template>

<script>
import graph from '../helpers/graph'

export default {
  data: function() {
    return {
      events: [],
      conferenceRoomTimer: null,
      error: ''
    }
  },
  watch: {
    // If events change, redraw calendar
    events: function() {
      this.drawCalendar()
    }
  },
  created() {
    let date = new Date();
    date.setHours(6,0,0,0); // hacky cuz we don't fuck around with time zones
    this.startdate = date.toISOString()
    date.setHours(20,59)
    //date.setDate(date.getDate() + 5)
    this.enddate = date.toISOString()


  },
  mounted(){
    this.fetchEvents()

    this.conferenceRoomTimer = setInterval(() => {
      this.fetchEvents()
    }, 240000) // every 240 seconds (4 minutes)

  },
  methods: {
    async fetchEvents() {
      try {
        let events = []
        // fetch json data from graph API
        let response = await graph.callGraph('/me/calendars/' + process.env.VUE_APP_CONFERENCE_ROOM_CALENDAR_ID + '/calendarView?startDateTime='+this.startdate +'&endDateTime='+ this.enddate)
        // loop over events, cannot be forEach beacuse of "break" statement
        for (let i = 0; i < response.value.length; i++) {

          // loop over ateendess of event, cannot be forEach beacuse of "break" statement
          for (let j = 0; j < response.value[i].attendees.length; j++) {
              //this is ugly, I just noticed the object hast a attendee type field, but meh, it works :)
              if (process.env.VUE_APP_CONFERENCE_ROOM_CALENDAR_ADDRESS !== response.value[i].attendees[j].emailAddress.address) {
                events.push({
                  "organizer" : response.value[i].attendees[j].emailAddress.name,
                  "address" : response.value[i].attendees[j].emailAddress.address,
                  "photo" : null,
                  "startdate" : response.value[i].start.dateTime,
                  "enddate": response.value[i].end.dateTime
                })
                console.log("break!");
                break;
              }

          } //inner loop
        }// outer loop
        console.log("events")
        console.log(events);
        // which persons are only in the "old" array and not in the new one?
        // -> remove them
        //let difference = this.presentPersons.filter(x => !persons.includes(x));
        let eventsToPop = this.events.filter(this.comparer(events))
        // pop them
        eventsToPop.forEach((eventToPop) => {
          this.events = this.events.filter(function(e) { return e.name !== eventToPop.name; });
        })

        // persons who are only in the "new" array
        let eventsToPush = events.filter(this.comparer(this.events))
        // get the photos for the new people
        for (let i = 0; i < eventsToPush.length; i++) {
          eventsToPush[i].photo = await graph.getPhoto('/users/' + eventsToPush[i].address +'/photos/240x240/$value', true)
        }
        // push them to array
        this.events = this.events.concat(eventsToPush)

      } catch (err) {
        this.error = err
        console.log(err)
      }
    }, //end of fetchEvents()
    //comparer:
    // https://stackoverflow.com/questions/21987909/how-to-get-the-difference-between-two-arrays-of-objects-in-javascript
    comparer(otherArray) {
      return function(current){
        return otherArray.filter(function(other){
          return other.value === current.value && other.display === current.display
        }).length === 0;
      }
    },
    // calculate all the dom elements and their styling classes from the this.events[] objects
    drawCalendar() {

      // todo:
      //  - calculate hours who are fully covered = recieve bg coor
      //  - calculate start and end and have custom classes for them
      //    + eg: start-0 start-15 start-30 start-45 and the same for end-xy

      //IDEA: make an array of classes and appy them to the parent element.
      // have them predefined in tailwind.config with ::nthElement (x) and so on

      this.events.forEach((event) => {
        let startdate = new Date(event.startdate)
        let enddate = new Date(event.enddate)

        let startdiv = startdate.getHours()
        let enddiv = enddate.getHours()

        console.log(startdiv)
        console.log(enddiv)
      })


    }
  }
}
</script>
